import React, { useEffect, useState } from 'react'
import { TextField, Accordion, AccordionSummary, AccordionDetails, Paper } from '@material-ui/core'
import { globalGetService } from '../../../utils/globalApiServices'
import { checkApiStatus } from '../../../utils'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { EmptyCollection } from '../../../shared_elements';
import config from '../../../config'
import STableLoader from '../../../shared_elements/components/skeleton_loader/STableLoader';

export default function FAQs() {
    const [searchResult, setSearchResult] = useState('')
    const [faqList, setFaqList] = useState([])
    const [isLoading, setPageLoader] = useState(false)
    useEffect(() => {
        getFaqsList()
    }, [])
    const getFaqsList = (query) => {
        setPageLoader(true)
        globalGetService('/console/faq/', { ...query, application: config.env.key === 'api' ? 18 : 19, })
            .then(response => {
                setPageLoader(false)
                if (checkApiStatus(response)) {
                    setFaqList(response.data.data)
                }
            })
    }
    const handleSearch = (value, callapi) => {
        if (value == 13 && callapi) {
            getFaqsList({ search: searchResult })
        }
        else if (value == '') {
            getFaqsList()
            setSearchResult(value)
        }
        else {
            setSearchResult(value)
        }
    }
    return (
        <section className='whats-new-container'>
            <div className='faq-header'>FAQ's
                <TextField
                    required
                    label='Search'
                    id="search"
                    placeholder='Search by Title or Content'
                    margin="normal"
                    onKeyDown={(e) => e.keyCode == 13 ? handleSearch(e.keyCode, true) : null}
                    value={searchResult || ''}
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 150 }}
                    onChange={(e) => handleSearch(e.target.value)}
                />
            </div>
            <div className='faq-body'>
                {!isLoading && faqList?.map((faqData, index) => (
                    <>
                        {
                            faqData?.section ?
                                <div className='faq-car-title'>{faqData?.question}</div>
                                :
                                <FaqSection
                                    faqData={faqData}
                                />
                        }
                    </>
                ))}
            </div>
            {
                isLoading ?
                    <STableLoader count={6} />
                    :
                    !faqList?.length ?
                        <Paper style={{ textAlign: 'center' }}>
                            <EmptyCollection
                                title={'No records found'}
                            />
                        </Paper>
                        : null
            }
        </section>
    )
}

const FaqSection = ({ faqData }) => {
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                {faqData?.question || ''}
            </AccordionSummary>
            <AccordionDetails style={{ background: '#f6f6f6' }}>
                <div className='faq-answer-section'>
                    <div dangerouslySetInnerHTML={{ __html: faqData?.answer || '' }}></div>
                </div>
            </AccordionDetails>
        </Accordion>
    )
}