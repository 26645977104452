import { React, useState, useEffect } from 'react'
import { Table, TableBody, Paper, Button, Grid, TextField, InputAdornment, TablePagination, Dialog } from "@material-ui/core";
import { globalGetService, globalDeleteService, globalExportService } from '../../../utils/globalApiServices';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import config from "../../../config"
import { DownloadAllApi } from './apiServices';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { FleetSbListing, FleetSbTableHeader, AddEditFleetSB } from '../components';
import { downloadFileType, checkApiStatus, getLocalStorageInfo } from '../../../utils';
import { EmptyCollection, PageLoader, DeletePopUp, ExportMenu } from '../../../shared_elements';
import FilterComponent from '../../../shared_elements/components/filter_component';
import STableLoader from '../../../shared_elements/components/skeleton_loader/STableLoader';
import { sbFilterOptions, sbSmbcFilterOptions } from './index';
import ShowAttachments from '../../../shared_elements/components/ShowAttachments';
import ImportFormat from '../../../shared_elements/components/ImportFormat';
import DeploymentMessage from '../../../shared_elements/components/DeploymentMessage';
import { permissionCheckFnforCamo } from '../../../utils';

function FleetMainSB({ match }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false)
    const [deletelist, setDeleteList] = useState({ modal: false, data: null })
    const [skeltonLoader, setSkeltonLoader] = useState(false)
    const [fleetSbListing, setFleetSbListing] = useState({ list: [], pagination: {} })
    const [sortInfo, setSortInfo] = useState({ sort: '', sort_by: '' });
    const [aaddEditFleetSB, setAddEditFleetSB] = useState({ modal: false, data: null, type: '' })
    const [importFile, setImportFile] = useState({ modal: false, data: null })
    const [filter, setFilter] = useState()
    const [applicability, setApplicability] = useState([])
    const [attchementsModal, setAttchementsModal] = useState({ modal: false, data: null, })

    useEffect(() => {
        getFleetSbListing({ per_page: 20, asset_type: 2 }, 'skeltonLoader')
        getApplicability()
    }, [])

    const createSortHandler = (key) => {
        let sortQuery = { sort: key };
        if (sortInfo.sort === key) {
            sortQuery = { ...sortQuery, sort_by: sortInfo.sort_by === 'asc' ? 'desc' : 'asc' }
        } else {
            sortQuery = { ...sortQuery, sort_by: 'asc' }
        }
        setSortInfo(sortQuery)
        getFleetSbListing({ ...sortQuery, per_page: 20, asset_type: 2 }, 'pageLoader');
    }


    const getFleetSbListing = (query, loaderType) => {
        loaderType === "skeltonLoader" ? setSkeltonLoader(true) : setLoading(true)
        globalGetService(`camo/fleet_sb/`, query)
            .then((response) => {
                loaderType === "skeltonLoader" ? setSkeltonLoader(false) : setLoading(false)
                if (response.status === 200) {
                    setFleetSbListing(response.data)
                    setFilter(query)
                }
            })
    }

    const deleteFleetSBList = () => {
        setLoading(true)
        globalDeleteService(`camo/fleet_sb/${deletelist?.data?.id}/`)
            .then((response) => {
                setLoading(false); setDeleteList({ modal: false }); getFleetSbListing({ per_page: 20, asset_type: 2 })
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response?.data?.message, { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, })
                } else {
                    enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
                }

            })
    }
    const exportSBListing = (extension) => {
        const date = new Date()
        const todayDate = moment(String(date)).format("DD-MM-YYYY")
        setLoading(true);
        globalExportService(`/camo/fleet_sb/2/export/`, { download: extension })
            .then(response => {
                setLoading(false);
                downloadFileType(response.data, `Fleet_SB_${todayDate}`, extension)
                enqueueSnackbar("Download Fleet SB List Successfully", { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            });
    }

    const getApplicability = () => {
        globalGetService(`console/engine-types/`)
            .then((response) => {
                if (response.status === 200) {
                    setApplicability(response?.data?.data?.engineTypes)
                }
            })
    }
    const deleteAttachements = (files, items, id) => {
        globalDeleteService(`camo/attachment/${id}/delete/?fleet=sb`)
            .then((response) => {
                if (response.data?.statusCode === 200) {
                    getFleetSbListing({ per_page: 20, asset_type: 2 }, 'pageLoader')
                    enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                } else {
                    enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

                }
            })

    }
    const openAttachment = (file) => {
        var decodedURL = decodeURIComponent(file);
        window.open(decodedURL, "_blank");
    };


    const filterObj = Object.assign({}, filter, delete filter?.per_page, delete filter?.sort, delete filter?.sort_by, delete filter?.asset_type)
    const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (config?.env?.key === "api" ? 465 : 532)
    let filterOptions = Object.assign({}, smbcInstance ? sbSmbcFilterOptions : sbFilterOptions);
    filterOptions = {
        ...filterOptions,
        sb_applicability: {
            ...filterOptions.sb_applicability,
            options: applicability
        },
    }
    return (
        <section className='camo-fleet-module'>
            <DeploymentMessage />
            {skeltonLoader ? <STableLoader count={7} /> : <>
                <Paper square variant='outlined' style={{ padding: '0px 5px', marginBottom: "8px" }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={3} md={3}>
                            <TextField
                                variant='outlined'
                                fullWidth
                                margin='normal'
                                size='small'
                                placeholder='Search ATA Chapter, SB Reference Number, SB Description, SB Type'
                                onChange={(e) => getFleetSbListing({ search: e.target.value, asset_type: 2, per_page: 20 }, 'pageloader')}
                                InputProps={{ style: { fontSize: 14, marginTop: 5 }, endAdornment: <InputAdornment position="end"><SearchIcon color='primary' cursor="pointer" /></InputAdornment> }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <div style={{ marginTop: "12px" }}>
                                <FilterComponent
                                    filter={filterObj}
                                    filterMenu={filterOptions}
                                    getResponseBack={(applyFilter) => getFleetSbListing({ ...applyFilter, page: 1, per_page: 20, asset_type: 2 }, 'pageLoader')}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <ul className='list-inline' style={{ float: "right", margin: "revert", }}>
                                {permissionCheckFnforCamo({ primaryKey: "ad_status", keyIndex: "C"}) ? <li className='list-inline-item' style={{ marginRight: "5px" }}>
                                    <Button variant='contained' color='primary' size='small' onClick={() => setAddEditFleetSB({ modal: true, data: null, type: 'add' })}> + Add SB</Button>
                                </li> : null}
                               {permissionCheckFnforCamo({ primaryKey: "ad_status", keyIndex: "C"}) ? <li className='list-inline-item' style={{ marginRight: "5px" }}>
									<Button fullWidth variant='outlined' color='primary' size='small' onClick={() => setImportFile({ modal: true, data: null })}>Import</Button>
								</li> : null}
                                <li className='list-inline-item'>
                                    <ExportMenu
                                        exportReportFn={(file) => exportSBListing(file.extension)}
                                        files={[{ title: 'PDF', extension: 'pdf' }, { title: 'EXCEL', extension: 'xls' }]}
                                    />
                                </li>
                            </ul>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper variant='outlined' square style={{ padding: '5px 10px', marginBottom: "5px" }}>
                    <div style={{ height: `${window.innerHeight - 230}px`, overflow: 'auto' }}>
                        <Table className='mui-table-format' stickyHeader={true}>
                            <FleetSbTableHeader
                                createSortHandler={(sort) => createSortHandler(sort)}
                                sortInfo={sortInfo}
                            />
                            <TableBody>
                                {fleetSbListing?.list?.map((item, index) =>
                                    <FleetSbListing
                                        item={item}
                                        key={index}
                                        toggleModalFn={() => setAddEditFleetSB({ modal: true, data: item, type: 'edit' })}
                                        toggleDelteModalFn={() => setDeleteList({ modal: true, data: item })}
                                        handleOpenAttachments={() => setAttchementsModal({ modal: true, data: item })}
                                    />
                                )}
                            </TableBody>
                        </Table>
                        {!fleetSbListing?.list?.length ? <EmptyCollection title="No Records Founds" /> : null}

                    </div>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                        component="div"
                        count={fleetSbListing?.pagination?.total}
                        rowsPerPage={parseInt(fleetSbListing?.pagination?.per_page)}
                        page={fleetSbListing?.pagination?.page - 1}
                        backIconButtonProps={{ "aria-label": "previous page" }}
                        nextIconButtonProps={{ "aria-label": "next page" }}
                        onChangePage={(event, newPage) => getFleetSbListing({ per_page: 20, page: newPage + 1, asset_type: 2 }, 'pageLoader')}
                        onChangeRowsPerPage={(event) => getFleetSbListing({ per_page: event.target.value, asset_type: 2 }, "pageLoader")}
                    />
                </Paper>
            </>}
            {aaddEditFleetSB.modal ?
                <AddEditFleetSB
                    fleetData={aaddEditFleetSB.data}
                    modeType={aaddEditFleetSB.type}
                    toggleModalFn={() => setAddEditFleetSB({ modal: false })}
                    getResponseBack={() => getFleetSbListing({ per_page: 20, asset_type: 2 })}
                /> : null
            }
            {deletelist.modal ?
                <DeletePopUp
                    title='Delete Fleet SB'
                    deleteRecordFn={() => deleteFleetSBList()}
                    modal={() => setDeleteList({ modal: true })}
                    content='Do you really want to delete this record?'
                    toggleModalFn={() => setDeleteList({ modal: false })}
                /> : null

            }
                {importFile.modal ?
                <ImportFormat
                    title="Import Fleet SB"
                    moduleType='fleet_sb'
					fleet={true}
                    moduleName="SampleFile Fleet SB"
                    getResponseBack={() => getFleetSbListing({ per_page: 20,asset_type:2 })}
                    toggleModalFn={() => setImportFile({ modal: false })}
                /> : null
            }
            {
                attchementsModal.modal ?
                    <Dialog
                        open={true}
                        onClose={() => setAttchementsModal({ modal: false, data: null })}
                        id="htDialog"
                    >
                        <ShowAttachments
                            handleClose={() => setAttchementsModal({ modal: false, data: null })}
                            handleShowAttachments={fleetSbListing?.list.filter(item => item?.id === attchementsModal?.data?.id).map(item => item?.attachments)[0]}
                            openAttachment={openAttachment}
                            files={{ title: "SB", extension: "zip", key: "" }}
                            downloadAllApi={() => DownloadAllApi(attchementsModal.data, 'SB')}
                            item={attchementsModal.data}
                            removeAttachment={deleteAttachements}
                        />
                    </Dialog>
                    :
                    null
            }
            {isLoading ? <PageLoader /> : null}
        </section>
    )
}

export default withRouter(FleetMainSB)