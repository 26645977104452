import React, { Component, Fragment } from "react"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { Container, Grid, Tooltip, IconButton, TextField, Button, Menu, MenuItem, } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer"
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive"
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver"
import UserDropDownMenu from "./UserDropDownMenu"
import { lessorUpdateApi } from "../../application/auth/apiServices"
import ReportAnIssue from "./ReportAnIssue"
import * as actions from "../actions"
import PageLoader from "./PageLoader"
import { getLocalStorageInfo, eraseGlobalCookie, authorizeAutoLogin, } from "../../utils"
import CamoMgmtDropdown from "./CamoMgmtDropdown"

class DesktopHeader extends Component {
	constructor(props) {
		super(props)
		this.state = {
			pageLoader: false,
			formSubmitLoader: false,
			lessor: null,
			reportAnIssueModal: false,
		}
		this.lessorUpdateApi = lessorUpdateApi.bind(this)
	}

	componentDidMount() {
		if (getLocalStorageInfo()) {
			let lessorValue = {
				id: getLocalStorageInfo().defaultLessor.id,
				name: getLocalStorageInfo().defaultLessor.name,
			}
			this.setState({ lessor: lessorValue })
		}
	}
	handleChange = (event, keyParam, value) => {
		this.setState({
			[keyParam]: value,
		})
	}

	render() {
		const { pageLoader, formSubmitLoader, lessor, reportAnIssueModal } = this.state
		return (
			<Fragment>
				<header className="desktop-header">
					<Container maxWidth={false} style={{ padding: "0px 5px" }}>
						<Grid
							container
							spacing={0}
							alignItems="center"
							justifyContent="center"
						>
							<Grid item xs={5}>
								<ul className="list-inline app-logo-search flex-centered">
									<li className="list-inline-item app-logo">
										<Link to="/">
											<img
												src="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/sparta_logo.png"
												alt="Sparta"
											/>
										</Link>
									</li>
									<li className="mui-menu">
										<CamoMgmtDropdown title="Airworthiness Management" />
									</li>
								</ul>
							</Grid>
							<Grid item xs={7}>
								<ul className="list-inline header-right-nav flex-centered">
									<li className="list-inline-item" >
										<Link to='/whats-new'>
											<Tooltip title="What's New" arrow>
												<IconButton size="small" color="primary">
													<RecordVoiceOverIcon color="primary" fontSize="small" />
												</IconButton>
											</Tooltip>
										</Link>
									</li>
									<li className="list-inline-item"  >
										<Tooltip title='Report An Issue' arrow><HeadsetMicIcon onClick={() => this.setState({ reportAnIssueModal: true })} fontSize='small' color='primary' /></Tooltip>

									</li>
									<li className="list-inline-item">
										<Link to='/faqs'>
											<Tooltip title="FAQs" arrow>
												<IconButton size="small" color="primary">
													<QuestionAnswerIcon color="primary" fontSize="small" />
												</IconButton>
											</Tooltip>
										</Link>
									</li>
									<li className="list-inline-item position-relative">
										<Link to='/notifications'>
											<Tooltip title="Notifications" arrow>
												<IconButton size="small" color="primary">
													<NotificationsActiveIcon
														color="primary"
														fontSize="small"
													/>
												</IconButton>
											</Tooltip>
										</Link>
									</li>
									<li
										className="list-inline-item border-left-grey"
										id="lessor-dropdown"
										style={{ width: "170px" }}
									>
										<Autocomplete
											style={{ width: "100%" }}
											options={getLocalStorageInfo() && getLocalStorageInfo().lessor_list ? getLocalStorageInfo().lessor_list : []}
											clearOnBlur={false}
											getOptionLabel={(option) => option.name}
											disableClearable={true}
											filterSelectedOptions={true} // set to false
											value={lessor}
											onChange={(event, newValue) => { authorizeAutoLogin({ lessor_id: newValue.id }) }}
											renderInput={(params) => (
												<TextField
													{...params}
													margin="none"
													fullWidth
													InputLabelProps={{ shrink: true }}
												/>
											)}
										/>
									</li>
									{/* <li className="list-inline-item" style={{width:'200px'}}>
										<Autocomplete
											options = {getLocalStorageInfo() && getLocalStorageInfo().lessor_list ? getLocalStorageInfo().lessor_list : []}
											clearOnBlur={false}
											getOptionLabel={option => option.name}
											disableClearable={true}
											filterSelectedOptions={true}
											onChange={(e, data) => this.lessorUpdateApi(this.props, {lessor_id:data.id}, true)}
											value={lessor}
											renderInput={params => <TextField {...params} margin="none" fullWidth InputLabelProps={{shrink: true}} />}
										/>
										</li> */}
									<li className="list-inline-item">
										<UserDropDownMenu
											logout={() => {
												eraseGlobalCookie("lessorAccess")
												eraseGlobalCookie("redirectURIMaintenance")
												eraseGlobalCookie("domain")
												eraseGlobalCookie("userName")
												this.props.history.push("/login")
												localStorage.clear()
											}}
										/>
									</li>
								</ul>
							</Grid>
						</Grid>
					</Container>
				</header>
				{reportAnIssueModal ?
					<ReportAnIssue
						reportAnIssueInfo={reportAnIssueModal}
						toggleModalFn={() => this.setState({ reportAnIssueModal: false })}
					/> : null
				}
				{pageLoader || formSubmitLoader ? <PageLoader /> : null}
			</Fragment>
		)
	}
}
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		toggleTheAppSwitcher: (flag) =>
			dispatch({ type: actions.APPS_SWITCHER, payload: flag }),
	}
}
export default withSnackbar(
	withRouter(connect(null, mapDispatchToProps)(DesktopHeader))
)
