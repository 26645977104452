import React, { useState, Fragment } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Checkbox, Tooltip, IconButton, TablePagination, Dialog } from "@material-ui/core";
import ConfirmDialog from "./ConfirmDialog";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { permissionCheckFnforCamo } from "../../../../utils";
import { LLpTableHeadMainHead, LLpTableHeadMainHead_B ,LLpTableHeadMainHead_C} from "../containers/LLpTableHead"
const LLpTable = ({ bulkOperation,bulkFlag, heads, noRecord, data, sort = "", sort_by = "", pagination = {}, onChangePage, onChangeRowsPerPage, createSortHandler, currentRecordPerPage, thrust_detail, bulkDeletefn, toggleBulkOps,basicDetails }) => {
	const [open, setOpen] = useState(false);
	const handleClick = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	const LLpTableHeadMainHeads_B = !permissionCheckFnforCamo({ primaryKey: "ad_status", keyIndex: "D"}) || window?.location?.search.includes('archived-assets') ?  LLpTableHeadMainHead_B.filter(item=>item.id != 1) : LLpTableHeadMainHead_B
	const LLpTableHeadMainHeads_C = !permissionCheckFnforCamo({ primaryKey: "ad_status", keyIndex: "D"}) || window?.location?.search.includes('archived-assets') ?  LLpTableHeadMainHead_C.filter(item=>item.id != 1) : LLpTableHeadMainHead_C
	const LLpTableHeadMainHeads = !permissionCheckFnforCamo({ primaryKey: "ad_status", keyIndex: "D"}) || window?.location?.search.includes('archived-assets') ?  LLpTableHeadMainHead.filter(item=>item.id != 1) : LLpTableHeadMainHead

	return (
		<Fragment>
			<div id="AmpTable" className="llpheaderStyle">
				<Table className="table-wrapper" id="llp">
					<TableHead>
					<TableRow>
							{( thrust_detail  && Object.values(thrust_detail)?.length === 2 ? LLpTableHeadMainHeads_B :  thrust_detail  && Object.values(thrust_detail)?.length === 3 ? LLpTableHeadMainHeads_C : LLpTableHeadMainHeads).map((row, index) => (
								<TableCell colSpan={row.colspan} align="center" key={index}>
									{row.label}
								</TableCell>
							))}
						</TableRow>
						<TableRow>
							{!permissionCheckFnforCamo({ primaryKey: "ad_status", keyIndex: "D"}) || window?.location?.search.includes('archived-assets')  ? null :bulkOperation ? (
								<TableCell>
									<Checkbox
										size="small"
										checked={bulkFlag}
										onChange={(e) => toggleBulkOps(e.target.checked)}
										name="bulk_operation"
										color="primary"
									/>
									{bulkOperation?.ids?.length > 0 && <Tooltip title="Delete" arrow>
										<IconButton className="delete-icon">
											<DeleteOutlinedIcon onClick={handleClick} />({bulkOperation?.ids?.length})
										</IconButton>
									</Tooltip>}
								</TableCell>
							) : null}
							{heads.map((row, index) => (
								<TableCell
									key={index}
									align={row.actionCell ? "right" : "left"}
									padding={"normal"}
									sortDirection={
										sort === row.id ? (sort_by ? sort_by : "asc") : false
									}
								>
									{row.sortOption && noRecord === null ? (
										<TableSortLabel
											active={sort === row.id}
											direction={sort_by && sort === row.id ? sort_by : "asc"}
											onClick={() => createSortHandler(row.id)}
										>
											{row.label}
										</TableSortLabel>
									) : (
										<span>{row.label}</span>
									)}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{noRecord ? (
							<TableRow id="table-no-records">
								<TableCell
									colSpan={bulkOperation ? heads.length + 1 : heads.length}
									align="center"
								>
									{noRecord}
								</TableCell>
							</TableRow>
						) : (
							data
						)}
					</TableBody>
				</Table>
			</div>
			{Object.keys(pagination).length && pagination.total > 0 ? (
				<TablePagination
					rowsPerPageOptions={[10, 20, 50, 100]}
					component="div"
					count={pagination.total}
					rowsPerPage={currentRecordPerPage}
					page={pagination.page - 1}
					backIconButtonProps={{ "aria-label": "previous page" }}
					nextIconButtonProps={{ "aria-label": "next page" }}
					onChangePage={onChangePage}
					onChangeRowsPerPage={onChangeRowsPerPage}
					showFirstButton={true}
					showLastButton={true}
				/>
			) : null}
			<Dialog open={open} onClose={handleClose} id="htDialog">
				<ConfirmDialog
					handleClose={() => handleClose()}
					handleDeleteRow={bulkDeletefn}
                    title={bulkOperation?.type === "bulk" ? "Do you really want to delete all the records?" :"Do you really want to delete selected records?"}
				/>
			</Dialog>
		</Fragment>
	)
}

export default LLpTable
