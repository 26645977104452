import React from 'react';
import { withRouter } from 'react-router-dom';
import { Tabs, Tab, Paper } from '@material-ui/core';
import { trackActivity } from '../../../utils/mixpanel';
const ConfgureTab = ({ tabIndex = 'disclaimer', history }) => {
    return (
        <Paper square style={{ marginBottom: '10px' }}>
            <Tabs indicatorColor='primary' value={tabIndex} onChange={(event, newValue) => history.push(`/configure/${newValue}`)} className={'no-margin-tab'}>
                <Tab label='Disclaimer' value="disclaimer" onClick={() => trackActivity('Tab Clicked', { page_title: 'Configure', event_type: 'Disclaimer Tab', event_desc: 'Clicked on Portfolio Tab from Reports' })} />
                <Tab label='LLP Template' value="llp" onClick={() => trackActivity('Tab Clicked', { page_title: 'Configure', event_type: ' LLP Template Tab', event_desc: 'Clicked on Engine off Wing Tab from Reports' })} />
            </Tabs>
        </Paper>
    )
}

export default withRouter(ConfgureTab);