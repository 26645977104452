import { globalDeleteService, globalGetService, globalExportService, globalPostService, globalFileUploadService, globalPutService, } from "../../../utils/globalApiServices";
import { downloadFileType, permissionCheckFnforCamo,getLocalStorageInfo ,checkApiStatus} from "../../../utils";
import { trackActivity } from "../../../utils/mixpanel";
import { assetType} from '../../../constants';
import moment from "moment";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';


export async function getUtilizationApi(queryParams = {}, loaderType, props = {}, searchString = null) {
    this.setState({ pageLoader: true });
    const { asset, assetType } = props?.match?.params;
    try {
        const response = await globalGetService(`camo/util/`, {
            ...queryParams,
            per_page: queryParams.per_page ? queryParams?.per_page : 50,
            asset: asset,
            asset_type: assetType,
            search: searchString,
        });

        if (response?.status === 500) {
            props.enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
            return null;
        } else {
            this.setState({
                pageLoader: false,
                assetsDetail: {
                    list: response?.data?.results,
                },
            });
            delete queryParams.page;
            delete queryParams?.per_page;
            delete queryParams.asset_type;
            delete queryParams.sort;
            delete queryParams.sort_by;
            this.setState((prevState) => ({
                ...prevState,
                filter: queryParams,
                assetsDetail: {
                    ...prevState.assetsDetail,
                    pagination: {
                        ...prevState.assetsDetail?.pagination,
                        total: response?.data?.pagination?.total,
                        per_page: response?.data?.pagination?.per_page,
                        page: parseInt(response?.data?.pagination?.current_page),
                    },
                    new_task_last_used_folder_uuid: response?.data?.new_task_last_used_folder_uuid,
                    last_used_folder_uuid_link_document: response?.data?.last_used_folder_uuid_link_document,
                    checkbox_last_used_folder_uuid: response?.data?.checkbox_last_used_folder_uuid,
                    checkbox_last_used_folder_name: response?.data?.checkbox_last_used_folder_name
                },
            }));
            return response;
        }
    } catch (error) {
        console.error("Error fetching data:", error);
        return null;
    }
}

export function deleteUtilAssetDetailRow(item, props, per_page) {
    globalDeleteService(`camo/util/${item.id}/`).then((response) => {
        if (response.status === 200) {
            props.enqueueSnackbar("Record Deleted Successfully", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, });
          
        }
        if (response.status === 500) {
            props.enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
        }
        this.getUtilizationApi({ per_page: per_page }, "skeletonLoader", props);
        // window.location.reload();
    });
}

export function addUtilFormApi(data, props) {
    if (permissionCheckFnforCamo({ primaryKey: "ad_status", keyIndex: "C", })) {
        return new Promise(function (resolve, reject) {
            globalPostService(`camo/util/`, data).then((response) => {
                if (response?.status === 500 || response.data?.statusCode === 500) {
                    props.enqueueSnackbar(response.data?.statusCode === 500 ? response.data?.message :"Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
                } else {
                    resolve(response);
                }
               
            });
        });
    }
    // window.location.reload();
}

export function fillUtilEditFormApi(item, props) {
    return new Promise(function (resolve, reject) {
        globalGetService(`camo/util/${item.id}/`).then((response) => {
            if (response.status === 500) {
                props.enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
            } else {
                resolve(response);
            }
        });
    });
}

export function UpdateUtilFormApi(updateId, updatedData, props, per_page) {
    if (permissionCheckFnforCamo({ primaryKey: "ad_status", keyIndex: "U", })) {
        this.setState({ pageLoader: true });
        globalPutService(`camo/util/${updateId}/`, updatedData).then(
            (response) => {
                if (response?.status === 500 || response.data?.statusCode === 500) {
                    props.enqueueSnackbar(response.data?.statusCode === 500 ? response.data?.message :"Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
                } else {
                    if (response) {
                        props.enqueueSnackbar("Record Updated Successfully", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, });
                        this.setState({ pageLoader: false });
                    }
                    this.getUtilizationApi({ per_page: per_page }, "skeletonLoader", props);
                    // window.location.reload();
                }
            }
        );

    }
    
}

export function uploadUtilFileApi(uploadId, formdata, props, per_page, haveAttachments) {
    this.setState({ pageLoader: true });
    if (haveAttachments) {
        globalFileUploadService(`camo/attachment/upload/`, formdata)
            .then((response) => {
                if (response.status === 500) {
                    props.enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
                } else {
                    if (response?.data?.statusCode === 200) {
                        this.setState({ pageLoader: false });
                        if (response.data.data.length > 0) {
                        }
                    }
                    this.getUtilizationApi({ per_page: per_page }, "skeletonLoader", props);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    } else {
        props.enqueueSnackbar("Data Submitted Successfully", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, });
        this.getUtilizationApi({ per_page: per_page }, "", props);
        // window.location.reload();
    }
}

export function exportUtilListApi(queryParams = {}, loaderType, props = {}) {
    this.setState({ pageLoader: true });
    const date = new Date();
    const todayeDate = moment(String(date)).format("DD-MM-YYYY");

    const { asset, assetType } = this.props.match.params;
    delete queryParams.asset_type;
    globalExportService(assetType == 2 ? `/camo/util/${asset}/${assetType}/export/` : `/camo/util/${asset}/${assetType}/export/`, {
        ...queryParams,
    }).then((response) => {
        if (response.status === 500) {
            props.enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
        } else {
            this.setState({ pageLoader: false });
            downloadFileType(response.data, `Utilisation_${todayeDate}`, queryParams.download);
            this.props.enqueueSnackbar("Download of Utilisation List successful", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, });
            trackActivity("Item Exported", {
                page_title: "Asset Listing",
            });
        }
    });
}

export function deleteAdAttachment(id, props) {
    globalDeleteService(`camo/attachment/${id}/delete/`).then((response) => {
        if (response.status === 500) {
            props.enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
        } else {
            if (response) {
                props.enqueueSnackbar("Attachment Deleted Successfully", {
                    variant: "success",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                });
            }
           
        }
    });
}
export function utilizatioPeriodDateApi(queryParams) {
    globalGetService(`camo/util/${queryParams.asset}/${queryParams.assetType}/period_list/`).then((response) => {
        if (response?.status === 200) {
            this.setState({utilDateList:response?.data})
        } 
    });
}

export function importUtilFileApi(formData, props, isXLSX, per_page) {
    if (isXLSX) {
        this.setState({ pageLoader: true });
        const { asset, assetType } = this.props.match.params;
        globalFileUploadService(`camo/util/${asset}/${assetType}/import/`, formData)
            .then((response) => {
                this.setState({ pageLoader: false });
                if (response?.data?.statusCode === 200) {
                    props.enqueueSnackbar(response?.data?.message, { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, });
                } else {
                    props.enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
                }
                this.getUtilizationApi({ per_page: per_page }, "skeletonLoader", props);
                // window.location.reload();

            })
            .catch((err) => {
                console.error(err);
                props.enqueueSnackbar("Please import proper file", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
            });
    } else {
        props.enqueueSnackbar("Please Import only .XLS File", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
    }
}

export function DownloadSvSampleFile(queryParams = {}, loaderType, props = {},basicDetails) {
    const {asset, assetType } = props.match.params
    globalExportService(`camo/util/${asset}/${assetType}/sample/?download=xls`).then((response) => {
        if (response.status === 500) {
            props.enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
        } else {
            downloadFileType(response.data, `Sample_Utilization_${basicDetails?.esn}`, queryParams.download);
            trackActivity("Item Exported", {
                page_title: "Asset Listing",
            });
        }
    });
}

export function exportMasterApi(queryParams = {}, loaderType, props = {}) {
	this.setState({ pageLoader: true });
	const date = new Date();
	const todayeDate = moment(String(date)).format("DD-MM-YYYY");

	const { asset, assetType } = this.props.match.params;
	delete queryParams.asset_type;
	globalExportService(`/camo/smbc_master_excel/${asset}/${assetType}/export/`)
		.then((response) => {
			if (response.status === 500) {
				props.enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
			} else {
				this.setState({ pageLoader: false });
				downloadFileType(response.data, `Master_Export_${todayeDate}`, queryParams.download);
				this.props.enqueueSnackbar("Master Export Download Successfully", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, });
				trackActivity("Master Export", {
					page_title: "Asset Listing",
				});
			}
		});
}

export function bulkDelete(props, bulkOperation, per_page) {
    const { asset, assetType } = props?.match?.params;
    this.setState({ pageLoader: true });
    globalDeleteService(`camo/util/bulk_delete/${assetType}/${asset}/`, { delete_ids: bulkOperation.ids.map(optionItem => optionItem.id) }).then((response) => {
        if (response?.data?.statusCode === 200) {
            this.setState({ pageLoader: false });
            props.enqueueSnackbar(response?.data?.message, { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, });
           
        } else {
            props.enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
        }
        this.getUtilizationApi({ per_page: per_page }, "skeletonLoader", props);
        this.setState({ bulkOperation: { modal: false, ids: [], title: '', type: '' } })
        // window.location.reload();
    });
}

export function onArchiveAsset(props,slug){
	globalGetService(`console/archive-asset/${assetType[props?.match?.params?.assetType]}/${slug}/true/`)
		.then(response => {
			if (checkApiStatus(response)) {
				props.enqueueSnackbar('Archive Status Updated Successfully', { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
				props.history.push("/assets-listing")
			}
		})
  }

export async function DownloadAllApi(queryParams = {}, loaderType, props = {}) {
    this.setState({ pageLoader: true })
    const attachment_list = queryParams.item.download_attachments;
    for (var i = 0; i < attachment_list.length; i++) {
        var encodedURL = attachment_list[i][1];
        var decodedURL = decodeURIComponent(encodedURL);
        attachment_list[i][1] = decodedURL;
    }
    const zip = new JSZip();
    const folder = zip.folder('DownloadedFiles');
    try {
        const downloadPromises = attachment_list.map(async (entry) => {
            const name = entry[0];
            const url = entry[1] || '';

            const res = await fetch(url);

            if (!res.ok) {
                throw new Error(`HTTP error! Status: ${res.status}`);
            }

            const blob = await res.blob();
            folder.file(name, blob);
        });

        await Promise.all(downloadPromises);

        const content = await zip.generateAsync({ type: 'blob' });
        saveAs(content, 'Utilization.zip');
        this.setState({ pageLoader: false })
    } catch (error) {
        console.error('Error:', error);
    }
}







