import React, { Fragment, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import { convertFilterObject, removeEmptyKey, permissionCheckFnforCamo, } from "../../../../utils";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import AddIcon from "@material-ui/icons/Add";
import { DropzoneArea } from 'material-ui-dropzone';
import { Grid, Button, Dialog, TextField, Menu, MenuItem, Tooltip, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { DownloadSvSampleFile } from "../apiServices";
import { isMobile } from "react-device-detect";
import DownloadXLSAssetDetail from "../../../../shared_elements/DownloadXLSAssetDetail";
import UtilizationFormData from "./UtilizationFormData";
import DownloadSample from "../../../../shared_elements/DownloadSample";
import DownloadPDFAssetDetail from "../../../../shared_elements/components/DownloadPDFAssetDetail";

const UtilizationSearchBar = ({ applyingFilter, tabIndex, pageLoader, props, searchData, fileUploadData, importFile, exportReportFn, currentRecordPerPage, uuid, getResponseBack, engineType, assetsDetail, basicDetails, techspecData }) => {
  const [open, setOpen] = useState(false);
  const [formTitle, setFormTitle] = useState("");
  const [actionType, setActionType] = useState("");
  const [exportAnchorEl, exportSetAnchorEl] = useState(null);
  const openExportMenu = Boolean(exportAnchorEl);

 
  const addNewTask = () => {
    setOpen(true);
    setFormTitle("Utilization");
    setActionType("add");
  };

  const closeAddForm = () => {
    setOpen(false);
  };




  const onExportHandleClose = () => {
    exportSetAnchorEl(null);
  };

  const onExportHandleClick = (event) => {
    exportSetAnchorEl(event.currentTarget);
  };

  const importFileFunc = (e) => {
    const files = e.target.files;
    const fileType = files[0].type;
    if (fileType.endsWith("application/vnd.ms-excel")) {
      let formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('file', files[i]);
        formData.append('redirect_url', window?.location?.href);
      }
      importFile(formData, props, true);
      e.target.value = null;
    } else {
      let formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('file', files[i]);
        formData.append('redirect_url', window?.location?.href);
      }
      importFile(formData, props, false);
      e.target.value = null;
    }

  };

  return (
    <Fragment>
      <div id="AmpSearchBar">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div className="search-input">
              <TextField
                id="search"
                placeholder="Search By Location(A/C Reg) Location (Country) Reason Of Removal "
                onChange={searchData}
              />
              <Tooltip
                title="Search By Location(A/C Reg) Location (Country) Reason Of Removal"
                variant="contained"
              >
                <SearchIcon className="search-icon" />
              </Tooltip>
            </div>
          </div>
          <div className="btn-wrapper">
            { !permissionCheckFnforCamo({ primaryKey: "ad_status", keyIndex: "C"}) ||  window?.location?.search.includes('archived-assets') ? null : (
              <Tooltip title="ADD UTILIZATION">
                <Button
                  onClick={addNewTask}
                  variant="contained"
                  color="primary"
                >
                  <AddIcon style={{ marginTop: "-1px" }} className="add-icon" />
                  ADD UTILIZATION
                </Button>
              </Tooltip>
            )}
            { !permissionCheckFnforCamo({ primaryKey: "ad_status", keyIndex: "C"}) || window?.location?.search.includes('archived-assets') ? null :
            <Button variant="outlined" color="primary" component="label">
              <span>IMPORT</span>
              <input
                className="upload-input"
                onChange={(e) => importFileFunc(e)}
                id="fileUplaod"
                name="file"
                type="file"
                accept="application/vnd.ms-excel"
              />
            </Button>}
            <Button
              variant="outlined"
              color="primary"
              id="basic-button"
              aria-controls={openExportMenu ? "basic-menu" : ""}
              aria-haspopup="true"
              aria-expanded={openExportMenu ? "true" : ""}
              onClick={onExportHandleClick}
            >
              EXPORT
              <KeyboardArrowDownIcon style={{ marginTop: "-1px" }} />
            </Button>
            <Menu
              className="import-menu-item export"
              id="basic-menu"
              anchorEl={exportAnchorEl}
              open={openExportMenu}
              onClose={onExportHandleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem className="export-list">
                <DownloadXLSAssetDetail
                  files={{ title: "EXCEL", extension: "xls", key: "" }}
                  exportReportFn={exportReportFn}
                />
              </MenuItem>
              <MenuItem className="export-list">
                <DownloadPDFAssetDetail
                  files={{ title: "PDF", extension: "pdf", key: "" }}
                  exportReportFn={exportReportFn}
                />
              </MenuItem>
            </Menu>
           {permissionCheckFnforCamo({ primaryKey: "ad_status", keyIndex: "C"}) ? <DownloadSample
              variant="outlined"
              color="primary"
              files={{ title: "DOWNLOAD SAMPLE", extension: "xls", key: "" }}
              exportSample={(file) => DownloadSvSampleFile({ download: file.extension, ...{ ...convertFilterObject(removeEmptyKey(applyingFilter),), asset_type: tabIndex, }, }, pageLoader, props, basicDetails)}
            /> : null}
            <Dialog open={open} style={{ height: isMobile && "650px" }}>
              <UtilizationFormData
                formTitle={formTitle}
                closeAddForm={() => closeAddForm()}
                props={props}
                actionType={actionType}
                fileUploadData={fileUploadData}
                currentRecordPerPage={currentRecordPerPage}
                uuid={uuid}
                getResponseBack={() => getResponseBack()}
                engineType={engineType}
                assetsDetail={assetsDetail}
                basicDetails={basicDetails}
                techspecData={techspecData}
              />
            </Dialog>
          </div>
        </div>
      </div>

    </Fragment>
  );
};
export default UtilizationSearchBar