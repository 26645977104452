import { React, useState, useEffect } from 'react'
import { Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Table, TableHead, TableBody, TableRow, TableCell } from "@material-ui/core"
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSnackbar } from 'notistack';
import { globalPutService, globalPostService, globalGetService } from '../../../utils/globalApiServices';
import { PageLoader } from '../../../shared_elements';
import { checkApiStatus, } from '../../../utils'
import { LLPTempObj } from '../containers';
import { format_Commas, } from '../../../utils';
import { regexConstants } from '../../../constants/regEx';

function AddEditLLpTemp({ llpdata, toggleModalFn, modeType, getResponseBack }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [data, setData] = useState(llpdata ? llpdata : LLPTempObj)
    const [error, setError] = useState({})
    const [isLoading, setLoading] = useState(false)
    const [engineType, setEngineType] = useState([])
    const [egineFamily, setEngineFamily] = useState([])

    useEffect(() => {
        getEngineFamily()
        if (data?.engine_family_id) {
            onChangeEngineFamily('engine_family_id', data?.engine_family_id)
        }
    }, [])

    const onFieldChange = (keyParam, value, index) => {
        const newData = { ...data };
        if (keyParam === 'engine_type_id' && !value) {
            newData[keyParam] = '';
            newData['cycle_limit'] = {};
            setTimeout(() => {
                setError({})
            }, 200);
        } else if (keyParam === 'engine_type_id') {
            globalGetService(`camo/llp_part_number/${value?.id}/thrust/`)
                .then((response) => {
                    if (response?.status === 200) {
                        setTimeout(() => {
                            setError({})
                        }, 200);
                        setData({ ...data, [keyParam]: value, thrust_details: response?.data?.thrust_details })
                    }
                })
        } else if (keyParam === "cycle_limit") {
            const thrustKey = index >= 0 ? `thrust_${String.fromCharCode(97 + index)}` : keyParam;
            const formattedValue = regexConstants.alphanumespcespclchar.test(value) ? value : format_Commas(value).replace(/[^0-9.]/g, '');
            newData[keyParam][thrustKey] = formattedValue;
            LLPTempObj.cycle_limit = {}
            if (regexConstants.alphanumespcespclchar.test(value)) {
                setError((prevError) => ({
                    ...prevError,
                    [thrustKey]: value === null || value === undefined || value === "" ? ""
                        : !['NO LIMIT'].includes(value.toUpperCase()) ? 'Allow keywords No limit or 0 to 99999' : ''
                }));
            } else {
                setError((prevError) => ({ ...prevError, [thrustKey]: value === null || regexConstants.numberWithComma.test(value) || value === "" ? "" : "" }));
            }

        } else {
            newData[keyParam] = value;
        }
        setData(newData);
    };

    console.log("error", error)

    const onChangeEngineFamily = (keyParam, value) => {
        if (keyParam === 'engine_family_id' && !value) {
            setData({ ...data, ['engine_family_id']: '', ['engine_type_id']: '', });
            setEngineType('')
            setTimeout(() => {
                setError({})
            }, 200);
        } else {
            globalGetService(`camo/engine_dropdown/${value?.id}/`,)
                .then(response => {
                    if (checkApiStatus(response)) {
                        setData({
                            ...data,
                            [keyParam]: value,
                            engine_type_id: response?.data?.engine_type?.filter(item => item?.id == data?.engine_type_id?.id)[0]
                        });
                        setEngineType(response?.data?.engine_type)
                    } else {
                        setData({ ...data, [keyParam]: value });
                    }
                })
        }
    }

    const getEngineFamily = () => {
        globalGetService(`camo/engine_dropdown/`)
            .then((response) => {
                if (response.status === 200) {
                    setEngineFamily(response?.data?.engine_family)
                }
            })
    }
    const onAddNewAsset = () => {
        let validationInputs = {};
        validationInputs = {
            ...validationInputs,
            part_number: data?.part_number ? '' : "Please Select Part Number",
            serial_number: data?.serial_number ? '' : "Please Enter Serial Number",
            description: data?.description ? '' : "Please Enter Description",
            engine_family_id: data?.engine_family_id ? '' : "Please Select Engine Family",
            engine_type_id: data?.engine_type_id ? '' : "Please Select Engine Type",
            module_type: data?.module_type ? '' : "Please Select Module Type",
        };
        if (data?.thrust_details) {
            Object.keys(data?.thrust_details)?.forEach((key, index) => {
                if (key.startsWith('thrust_')) {
                    const value = data?.cycle_limit[key];
                    if (value) {
                        if (typeof value === 'string' && value?.toUpperCase() === 'NO LIMIT') {
                            validationInputs[key] = '';
                        }
                        else {
                            const stringValue = typeof value === 'string' ? value : String(value);
                            if (/^[0-9,]+$/.test(stringValue)) {
                                const numericValue = parseInt(stringValue?.replace(/,/g, ''), 10);
                                if (numericValue >= 0 && numericValue <= 99999) {
                                    validationInputs[key] = '';
                                } else {
                                    validationInputs[key] = 'Allow keywords No limit or 0 to 99999';
                                }
                            } else {
                                validationInputs[key] = 'Allow keywords No limit or 0 to 99999';
                            }
                        }
                    } else {
                        validationInputs[key] = `Please Enter Thrust ${String.fromCharCode(97 + index)}`;
                    }
                }
            });
        }
        if (Object.keys(validationInputs).every((k) => validationInputs[k] === '')) {
            setLoading(true);
            let payload = Object.assign({}, data);
            payload = {
                ...payload,
                engine_type_id: payload?.engine_type_id?.id,
                engine_family_id: payload?.engine_family_id?.id,
            };
            delete payload?.id;
            const apiUrl = data?.id ? `camo/llp_part/2/${data?.id}/` : `camo/llp_part/2/`;
            const apiMethod = data?.id ? globalPutService : globalPostService;
            apiMethod(apiUrl, payload)
                .then((response) => {
                    setLoading(false);
                    if (response.data?.statusCode === 200) {
                        enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                        toggleModalFn();
                        getResponseBack();
                    } else {
                        enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    enqueueSnackbar("An error occurred while processing your request.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });
                });
        } else {
            setError(validationInputs);
        }
    };
    const lifeLimitValue = (index) => {
        const thrustKey = `thrust_${String.fromCharCode(97 + index)}`;
        return format_Commas(data?.cycle_limit[thrustKey]) || '';
    }

    const lifeLimithelperText = (index) => {
        const thrustKey = `thrust_${String.fromCharCode(97 + index)}`;
        return error?.[thrustKey] || "";
    }

    const lifeLimitError = (index) => {
        const thrustKey = `thrust_${String.fromCharCode(97 + index)}`;
        return !!error?.[thrustKey];
    }
    return (
        <section className='llp-templates'>
            <Dialog
                open={true}
                onClose={toggleModalFn}
            >
                <DialogTitle> {modeType === "edit" ? "Edit LLP Template" : "Add LLP Template"} </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={1} >
                        <Grid item xs={12} md={3} sm={3}>
                            <Autocomplete
                                id="engine_family_id"
                                options={egineFamily || []}
                                getOptionLabel={(option) => option?.name}
                                value={data?.engine_family_id || null}
                                margin='normal'
                                disabled={modeType==='edit'}
                                onFocus={() => setError({ ...error, 'engine_family_id': '' })}
                                onChange={(e, value) => onChangeEngineFamily("engine_family_id", value)}
                                renderInput={(params) => (
                                    <TextField
                                        required
                                        {...params}
                                        label='Engine Family '
                                        error={error?.engine_family_id ? true : false}
                                        helperText={error?.engine_family_id ? error?.engine_family_id : ""}
                                        margin='normal'
                                        placeholder="Select Engine Family"
                                        variant="outlined"
                                        fullWidth
                                        disabled={modeType==='edit'}
                                        InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <Autocomplete
                                id="engine_type_id"
                                options={engineType || []}
                                getOptionLabel={(option) => option?.name}
                                value={data?.engine_type_id || []}
                                margin='normal'
                                disabled={modeType==='edit'}
                                onFocus={() => setError({ ...error, 'engine_type_id': '' })}
                                onChange={(e, value) => onFieldChange("engine_type_id", value)}
                                renderInput={(params) => (
                                    <TextField
                                        required
                                        {...params}
                                        error={error?.engine_type_id ? true : false}
                                        helperText={error?.engine_type_id ? error?.engine_type_id : ""}
                                        label="Engine Type"
                                        margin='normal'
                                        placeholder="Select Engine Type"
                                        variant="outlined"
                                        fullWidth
                                        disabled={modeType==='edit'}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3} >
                            <TextField
                                required
                                id="part_number"
                                label="Part Number"
                                margin='normal'
                                placeholder="Enter Part Number"
                                variant="outlined"
                                fullWidth
                                inputProps={{ maxLength: 50 }}
                                error={error?.part_number ? true : false}
                                helperText={error?.part_number ? error?.part_number : ""}
                                value={data?.part_number}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => onFieldChange("part_number", e.target.value)}
                                onFocus={() => setError({ ...error, 'part_number': '' })}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3} >
                            <Autocomplete
                                id="module_type"
                                options={["FAN", "HPC", "HPT", "LPC", "LPT", "STATIC", "BLADES"]}
                                getOptionLabel={(option) => option}
                                value={data?.module_type || []}
                                margin='normal'
                                onFocus={() => setError({ ...error, 'module_type': '' })}
                                onChange={(e, value) => onFieldChange("module_type", value)}
                                renderInput={(params) => (
                                    <TextField
                                        required
                                        {...params}
                                        error={error?.module_type ? true : false}
                                        helperText={error?.module_type ? error?.module_type : ""}
                                        label="Module Type"
                                        margin='normal'
                                        placeholder="Select Module Type"
                                        variant="outlined"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} >
                        <Grid item xs={12} md={3} sm={3} >
                            <TextField
                                required
                                variant="outlined"
                                margin='normal'
                                label="Serial Number"
                                id="serial_number"
                                fullWidth
                                inputProps={{ maxLength: 50 }}
                                error={error?.serial_number ? true : false}
                                helperText={error?.serial_number ? error?.serial_number : ""}
                                value={data?.serial_number}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => onFieldChange("serial_number", e.target.value)}
                                onFocus={() => setError({ ...error, 'serial_number': '' })}
                            />
                        </Grid>
                        <Grid item xs={12} md={9} sm={9} >
                            <TextField
                                required
                                variant="outlined"
                                margin='normal'
                                label="Description"
                                id="description"
                                fullWidth
                                inputProps={{ maxLength: 255 }}
                                error={error?.description ? true : false}
                                helperText={error?.description ? error?.description : ""}
                                value={data?.description}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => onFieldChange("description", e.target.value)}
                                onFocus={() => setError({ ...error, 'description': '' })}
                            />
                        </Grid>
                    </Grid>
                    {modeType === "edit" ?<p style={{margin:'10px 0px'}}>
                        <span style={{fontWeight:"800px"}}>Please note:</span> <span style={{background:'#f9f96f'}}>The data will be updated in the LLP status.</span>
                    </p> : null}
                    <Table className='mui-table-format' stickyHeader={true}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Thrust</TableCell>
                                <TableCell>Cycle Limit <sup style={{ color: " #ff0000", fontSize: "14px", position: "relative", top: 0 }}> * </sup></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.engine_type_id?.id ? data?.thrust_details && Object.values(data?.thrust_details)?.map((item, index) => (
                                <TableRow >
                                    <TableCell> {item} </TableCell>
                                    <TableCell className='llp-temp-cycle-limit'>
                                        <TextField
                                            className='llp-temp-cycle-limit'
                                            variant="outlined"
                                            margin='normal'
                                            style={{ width: '200px' }}
                                            value={lifeLimitValue(index) || ''}
                                            error={lifeLimitError(index)}
                                            helperText={lifeLimithelperText(index)}
                                            onFocus={() => setError({ ...error, 'cycle_limit': '' })}
                                            inputProps={{ maxLength: regexConstants.alphanumespcespclchar.test(lifeLimitValue(index)) ? 8 : 6 }}
                                            onChange={(e) => {
                                                if (regexConstants.alphanumespcespclchar.test(e.target.value)) {
                                                    onFieldChange("cycle_limit", e.target.value, index);
                                                } else if (regexConstants.onlyNumeric.test(e.target.value?.replace(',', ''))) {
                                                    onFieldChange("cycle_limit", e.target.value, index);
                                                } else {
                                                    (regexConstants.onlyNumeric.test(format_Commas(e.target.value)?.replace(/[^0-9.]/g, '')) || e.target.value === "") && onFieldChange('cycle_limit', e.target.value, index)
                                                }
                                            }
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            )
                            )
                                : null}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color='primary' onClick={toggleModalFn}>Close</Button>
                    <Button variant='contained' color='primary' onClick={onAddNewAsset}>SAVE</Button>
                </DialogActions>
            </Dialog>
            {isLoading ? <PageLoader /> : null}
        </section>
    )
}

export default AddEditLLpTemp