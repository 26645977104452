import { React, useState, useEffect, Fragment } from 'react'
import { Table, TableBody, Paper, Button, Grid, TableRow, TableCell, IconButton, Tooltip, TableHead, TableSortLabel } from "@material-ui/core";
import { globalGetService, globalDeleteService, globalExportService } from '../../../utils/globalApiServices';
import { useSnackbar } from 'notistack';
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { ConfgureTab, AddEditLLpTemp, ImportInventory } from '../components';
import { downloadFileType, checkApiStatus } from '../../../utils';
import { EmptyCollection, PageLoader, DeletePopUp, ExportMenu } from '../../../shared_elements';
import FilterComponent from '../../../shared_elements/components/filter_component';
import STableLoader from '../../../shared_elements/components/skeleton_loader/STableLoader';
import { LLPTempObj, LLPTempFilterOptions } from '.';
import { permissionCheckFnforCamo, } from '../../../utils';
import Pagination from '../../../shared_elements/components/Pagination';
const LLpTemplateHeader = ({ sortInfo, createSortHandler }) => {
    return (
        <TableHead>
            <TableRow>
                <TableCell><TableSortLabel active={sortInfo.sort === 'engine_family_id' ? true : false} direction={sortInfo.sort === 'engine_family_id' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('engine_family_id')} > Engine Family </TableSortLabel></TableCell>
                <TableCell><TableSortLabel active={sortInfo.sort === 'engine_type_id' ? true : false} direction={sortInfo.sort === 'engine_type_id' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('engine_type_id')} > Engine Type </TableSortLabel></TableCell>
                <TableCell><TableSortLabel active={sortInfo.sort === 'module_type' ? true : false} direction={sortInfo.sort === 'module_type' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('module_type')} > Module </TableSortLabel></TableCell>
                <TableCell><TableSortLabel active={sortInfo.sort === 'description' ? true : false} direction={sortInfo.sort === 'description' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('description')} > Description </TableSortLabel></TableCell>
                <TableCell><TableSortLabel active={sortInfo.sort === 'part_number' ? true : false} direction={sortInfo.sort === 'part_number' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('part_number')} > Part number </TableSortLabel></TableCell>
                <TableCell><TableSortLabel active={sortInfo.sort === 'serial_number' ? true : false} direction={sortInfo.sort === 'serial_number' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('serial_number')} > Serial Number </TableSortLabel></TableCell>
                <TableCell> Actions </TableCell>
            </TableRow>
        </TableHead>
    )
}

const LLpTemplateList = ({ item, toggleModalFn, toggleDelteModalFn }) => {
    return (
        <TableRow>
            <TableCell>{item?.engine_family_id?.name || '--'}</TableCell>
            <TableCell>{item?.engine_type_id?.name || '--'}</TableCell>
            <TableCell>{item?.module_type || '--'}</TableCell>
            <TableCell>{item?.description || '--'}</TableCell>
            <TableCell>{item?.part_number || '--'}</TableCell>
            <TableCell>{item?.serial_number || '--'}</TableCell>
            <TableCell>
                {permissionCheckFnforCamo({ primaryKey: "ad_status", keyIndex: "U" }) ? <Tooltip title="Edit" placement='top'>
                    <IconButton style={{ padding: 'unset' }} color='primary' onClick={toggleModalFn}><CreateOutlinedIcon /> </IconButton>
                </Tooltip> : null}
                {permissionCheckFnforCamo({ primaryKey: "ad_status", keyIndex: "D" }) ? <Tooltip title="Delete" placement='top'>
                    <IconButton style={{ padding: 'unset' }} color='secondary' onClick={toggleDelteModalFn} > <DeleteOutlinedIcon /> </IconButton>
                </Tooltip> : null}
            </TableCell>
        </TableRow>
    )
}

function LlpTemplate({ match }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [sortInfo, setSortInfo] = useState({ sort: '', sort_by: '' });
    const [filter, setFilter] = useState({});
    const [LLpTemplate, setLLpTemplate] = useState({ results: [], pagination: { page: 1, per_page: 20, total: 100 } });
    const [addEditLLpTemplate, setaddEditLLpTemplate] = useState({ modal: false, mode: '', data: null });
    const [deleteLLpTemplate, setDeleteLLpTemplate] = useState({ modal: false, data: null });
    const [egineFamily, setEngineFamily] = useState([])

    useEffect(() => {
        getEngineFamily()
        getLLPTemplate({ ...filter, per_page: 20 }, 'skeletonLoader')
    }, [])

    const getEngineFamily = () => {
        globalGetService(`camo/engine_dropdown/`)
            .then((response) => {
                if (response?.status === 200) {
                    setEngineFamily(response?.data?.engine_family)
                }
            })
    }
    const createSortHandler = (key) => {
        let sortQuery = { sort: key };
        if (sortInfo.sort === key) {
            sortQuery = { ...sortQuery, sort_by: sortInfo.sort_by === 'asc' ? 'desc' : 'asc' }
        } else {
            sortQuery = { ...sortQuery, sort_by: 'asc' }
        }
        setSortInfo(sortQuery)
        getLLPTemplate({ ...filter, ...sortQuery, page: 1, per_page: LLpTemplate?.pagination?.per_page || 20 }, 'pageLoader');

    }

    const getLLPTemplate = (query = {}, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true)
        globalGetService(`camo/llp_part/2/`, query)
            .then(response => {
                if (checkApiStatus(response)) {
                    setLLpTemplate(response.data);
                    delete query.page
                    delete query.per_page
                    delete query.sort
                    delete query.sort_by
                    setFilter(query)
                    loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false)
                } else {
                    loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false)
                    enqueueSnackbar(response?.data?.message ? response?.data?.message : "something went wrong !", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });
                }
            })
    }
    const onDeletellpTemplate = () => {
        setLoading(true);
        globalDeleteService(`camo/llp_part/2/${deleteLLpTemplate?.data?.id}/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setLoading(false);
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    setDeleteLLpTemplate({ modal: false, data: null });
                    getLLPTemplate({ ...filter, per_page: LLpTemplate?.pagination?.per_page || 20 })
                }
            })
    }
    const exportLlpTemp = (extension) => {
        setLoading(true);
        let queryParams = {}
        if (sortInfo?.sort === '' && sortInfo?.sort_by === '') {
            queryParams = Object.assign({}, filter);
        } else {
            queryParams = Object.assign({}, filter, sortInfo);
        }
        globalExportService(`camo/llp_part_number/2/export/`, { download: extension, ...queryParams })
            .then(response => {
                setLoading(false);
                downloadFileType(response?.data, 'LLP Template_Reports', extension)
            });
    }

    const filterObj = Object.assign({}, filter, delete filter.sort, delete filter.sortInfo)
    let filterOptions = Object.assign({}, LLPTempFilterOptions);
    filterOptions = {
        ...filterOptions,
        engine_family_id: {
            ...filterOptions.engine_family_id,
            options: egineFamily
        },
    }
    return (
        <>
            <ConfgureTab tabIndex="llp" />
            <section className='camo-fleet-module'>
                {skeletonLoader ? <STableLoader count={10} /> :
                    <>
                        <Paper square elevation={2} variant='outlined' style={{ padding: '5px 10px' }}>
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item md={9}>
                                    <div style={{ marginTop: "2px" }}>
                                        <FilterComponent
                                            filter={filterObj}
                                            filterMenu={filterOptions}
                                            getResponseBack={(applyFilter) => getLLPTemplate({ ...applyFilter, page: 1, per_page: LLpTemplate?.pagination?.per_page || 20 }, 'pageLoader')}
                                        />
                                    </div>
                                </Grid>
                                <Grid item md={3}>
                                    <ul className='list-inline' style={{ float: 'right' }}>
                                        {permissionCheckFnforCamo({ primaryKey: "ad_status", keyIndex: "C" }) ? <li className='list-inline-item' style={{ marginRight: "5px", marginLeft: "5px" }}>
                                            <Button onClick={() => setaddEditLLpTemplate({ modal: true, mode: 'add', data: null })} color='primary' size="small" variant="contained"> + Add Template</Button>
                                        </li> : null}
                                        {permissionCheckFnforCamo({ primaryKey: "ad_status", keyIndex: "C" }) ? <li className='list-inline-item' style={{ marginRight: "5px" }}>
                                            <ImportInventory
                                                downloadSampleURL="camo/llp_part_number/2/sample/"
                                                importURl="camo/llp_part_number/2/import/"
                                                title="Import LLP Template"
                                                dsName='LLP Template'
                                                getResponseBack={() => getLLPTemplate({ ...filter, per_page: LLpTemplate?.pagination?.per_page || 20 }, 'pageLoader')}
                                            />
                                        </li> : null}
                                        <li className='list-inline-item' style={{ marginRight: "5px" }}>
                                            <ExportMenu
                                                exportReportFn={(file) => exportLlpTemp(file.extension)}
                                                files={[{ title: 'EXCEL', extension: 'xls' }, { title: 'PDF', extension: 'pdf' }]}
                                                title='EXPORT'
                                            />
                                        </li>
                                    </ul>
                                </Grid>
                            </Grid>
                        </Paper>
                        <Paper variant='outlined' square style={{ marginBottom: "5px" }}>
                            <div style={{ height: !LLpTemplate?.results?.length ? 'auto' : `${window.innerHeight - 250}px`, overflow: 'auto' }}>
                                <Table className='mui-table-format' stickyHeader={true}>
                                    <LLpTemplateHeader
                                        sortInfo={sortInfo}
                                        createSortHandler={createSortHandler}
                                    />
                                    {LLpTemplate?.results?.length > 0 && LLpTemplate?.results?.map((item, index) =>
                                        <TableBody>
                                            <LLpTemplateList
                                                item={item}
                                                toggleModalFn={() => setaddEditLLpTemplate({ modal: true, data: item, type: 'edit' })}
                                                toggleDelteModalFn={() => setDeleteLLpTemplate({ modal: true, data: item })}
                                            />
                                        </TableBody>
                                    )}
                                </Table>
                                {!LLpTemplate?.results?.length ? <div style={{ textAlign: 'center' }}><EmptyCollection title='No records found' /></div> : null}
                            </div>
                            <Pagination
                                pagination={LLpTemplate?.pagination}
                                onChangePage={(event, newPage) => getLLPTemplate({ ...filter, sort: sortInfo?.sort, sort_by: sortInfo?.sort_by, page: newPage + 1, per_page: LLpTemplate?.pagination?.per_page || 20 }, 'pageLoader')}
                                onChangeRowsPerPage={(event) => getLLPTemplate({ ...filter, page: 1, sort: sortInfo?.sort, sort_by: sortInfo?.sort_by, per_page: event.target.value }, 'pageLoader')}
                            />
                        </Paper>
                    </>
                }
                {addEditLLpTemplate?.modal ?
                    <AddEditLLpTemp
                        llpdata={addEditLLpTemplate?.data}
                        modeType={addEditLLpTemplate?.type}
                        toggleModalFn={() => setaddEditLLpTemplate({ modal: false })}
                        getResponseBack={() => getLLPTemplate({ ...filter, per_page: addEditLLpTemplate?.pagination?.per_page || 20 }, 'pageLoader')}
                    /> : null
                }
                {deleteLLpTemplate.modal ?
                    <DeletePopUp
                        modal={deleteLLpTemplate.modal}
                        title='Delete LLP Template'
                        content={`Are you sure, you want to Delete?`}
                        toggleModalFn={() => setDeleteLLpTemplate({ modal: false, data: null })}
                        deleteRecordFn={onDeletellpTemplate}
                        extraNote={true}
                    /> : null
                }
                {isLoading ? <PageLoader /> : null}
            </section >
        </>
    )
}

export default withRouter(LlpTemplate)
