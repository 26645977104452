import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Dialog, TableRow, TableCell, Tooltip, IconButton, } from "@material-ui/core";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import ConfirmDialog from "../components/ConfirmDialog";
import { fillUtilEditFormApi } from "../apiServices";
import UtilizationFormData from "./UtilizationFormData";
import moment from "moment";
import { backendDateFormat, dayMonthDateFormat } from "../../../../constants"
import ShowAttachments from "../../../../shared_elements/components/ShowAttachments";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { permissionCheckFnforCamo, checkApiStatus,Comma_format } from "../../../../utils";
import { globalPutService, globalDeleteService } from "../../../../utils/globalApiServices";
import RevisionHistory from "../../../../shared_elements/components/RevisionHistory";

const UtilizationList = ({ item, index, handleDeleteRow, updateFormData, props, fileUploadData, currentRecordPerPage, downloadAllApi, uuid, last_used_folder_uuid, getResponseBack, last_used_folder_uuid_link_document, checkbox_last_used_folder_uuid, checkbox_last_used_folder_name, engineType, assetsDetail, basicDetails }) => {
    const [open, setOpen] = useState(false);
    const [openEditForm, setEditForm] = useState(false);
    const [editFormId, setEditFormId] = useState(null);
    const [editFormData, setEditFormData] = useState(null);
    const [formTitle, setFormTitle] = useState("");
    const [attachments, setAttachments] = useState(null);
    const [showFullDescription, setShowFullDescription] = useState({});
    const [openGetAttachmentsDialogue, setAttachmentsDialogue] = useState(false);
    const [AllAttachmentsforDialogue, setAllAttachmentsforDialogue] =
        useState(null);
    const [moduleId, setmoduleId] = useState(null);
    const [actionType, setActionType] = useState("");

    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const closeAddForm = () => {
        setEditForm(false);
    };

    const handleEditRow = () => {
        fillUtilEditFormApi(item, props).then((response) => {
            setEditFormData(response.data.data);
            setEditFormId(response.data.data.id);
            setAttachments(response.data.data.attachments);
            setFormTitle("Update Utilization");
            setEditForm(true);
            setActionType('edit')
        });
    };

    const downAllAttach = () => {
        fillUtilEditFormApi(item, props).then((response) => {
            setmoduleId(response.data.data.id);
        });
        getAttachements()
    };
    const getAttachements = () => {
        const matchingItem = assetsDetail?.find(items => items?.id === item.id);
        if (!matchingItem) return [];
        const { checklist, attachments } = matchingItem || {};
        if (checklist && attachments || checklist || attachments) {
            const { files, folders } = checklist || {}; // Add null check here
            if (attachments?.length > 0 && files?.length > 0 && folders?.length > 0) {
                getAttachments([...attachments, ...files, ...folders])
            } else if (files?.length > 0 && folders?.length > 0) {
                getAttachments([...folders, ...files])
            } else if (attachments?.length > 0 && files?.length > 0) {
                getAttachments([...attachments, ...files])
            } else if (attachments?.length > 0 && folders?.length > 0) {
                getAttachments([...attachments, ...folders])
            } else if (attachments?.length > 0) {
                getAttachments([...attachments])
            } else if (folders?.length > 0) {
                getAttachments([...folders])
            } else if (files?.length > 0) {
                getAttachments([...files])
            }
        }
        return [];

    };

    const openAttachment = (file) => {
        var decodedURL = decodeURIComponent(file);
        window.open(decodedURL, "_blank");
    };

    const formatDate = (date) => {
        return moment(date).format(dayMonthDateFormat);
    };

    const toggleDescription = (id) => {
        setShowFullDescription({
            ...showFullDescription,
            [id]: !showFullDescription[id],
        });
    };

    const getAttachments = (attachments) => {
        setAllAttachmentsforDialogue(attachments);
        setAttachmentsDialogue(true);
    };

    const handleCloseAttachmentDialogue = () => {
        setAttachmentsDialogue(false);
    };
    const removeAttachment = (item, checklist, fileId, uuid) => {
        const removeID = item.attachments.map((data) => data.id).filter((id) => id).join(',')
        const attchementRemoveID = checklist.map((file) => file.id).filter((id) => !removeID.includes(id))
        if (uuid) {
            let payload = {};
            payload.delete = true;
            payload.folder_ids = checklist.filter(items => items.type === 'folder').map(file => file.id).filter((id) => id !== fileId).toString()
            payload.file_ids = checklist.filter(items => items.type === 'file').map(file => file.id).filter((id) => id !== fileId).toString()
            globalPutService(`camo/util/${item?.id}/document-checklists/`, payload)
                .then((response) => {
                    if (checkApiStatus(response)) {
                        fillUtilEditFormApi(item, props).then((response) => {
                            setEditFormData(response.data.data);
                            const matchingItem = response.data.data;
                            if (!matchingItem) return [];
                            const { checklist, attachments } = matchingItem || {};
                            if (checklist && attachments || checklist || attachments) {
								if (item?.attachments?.length > 0 && item.checklist?.files?.length > 0 && item?.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response.data?.data?.checklist?.files, ...response.data.data.checklist.folders])
								}else if (item?.attachments?.length > 0 && item.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response.data.data.checklist.folders])
								} else if (item?.attachments?.length > 0 && item.checklist?.files?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response?.data?.data?.checklist?.files])
								} else if (item.checklist?.files?.length > 0 && item.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.checklist.files, ...response.data.data.checklist.folders])
								} else if (item?.attachments?.length > 0) {
									getAttachments([...response.data.data.attachments])
								} else if (item.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.checklist.folders])
								} else if (item.checklist?.files?.length > 0) {
									getAttachments([...response?.data?.data?.checklist?.files])
								}
								getResponseBack()
							}

                            return [];
                        });
                    }
                })
        } else {
            globalDeleteService(`camo/attachment/${fileId}/delete/`).then((response) => {
                if (response.status === 500) {
                    props.enqueueSnackbar("Something went wrong.", {
                        variant: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                    });
                } else {
                    if (response) {
                        fillUtilEditFormApi(item, props).then((response) => {
                            setEditFormData(response.data.data);
                            const matchingItem = response.data.data;
                            if (!matchingItem) return [];
                            const { checklist, attachments } = matchingItem || {};
                            if (checklist && attachments || checklist || attachments) {
								if (item?.attachments?.length > 0 && item.checklist?.files?.length > 0 && item?.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response.data?.data?.checklist?.files, ...response.data.data.checklist.folders])
								}else if (item?.attachments?.length > 0 && item.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response.data.data.checklist.folders])
								} else if (item?.attachments?.length > 0 && item.checklist?.files?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response?.data?.data?.checklist?.files])
								} else if (item.checklist?.files?.length > 0 && item.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.checklist.files, ...response.data.data.checklist.folders])
								} else if (item?.attachments?.length > 0) {
									getAttachments([...response.data.data.attachments])
								} else if (item.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.checklist.folders])
								} else if (item.checklist?.files?.length > 0) {
									getAttachments([...response?.data?.data?.checklist?.files])
								}
								getResponseBack()
							}

                            return [];
                        });
                    }
                }
            });
        }
    };
    let timezone = moment().format()
    const timestamp = Math.floor(Date.now() / 1000);
    const utilurl = `audit/camo/utilization/?object_id=${item.id}&action=0,1&timezone=${timezone}&timestamp=${timestamp}`
    function getAttachmentCount(item) {
        const { attachments, checklist } = item;
        const { files, folders } = checklist || {};

        const attachmentCount = attachments?.length || 0;
        const fileCount = files?.length || 0;
        const folderCount = folders?.length || 0;

        if (attachmentCount && fileCount && folderCount) {
            return attachmentCount + fileCount + folderCount;
        } else if (fileCount && folderCount) {
            return fileCount + folderCount;
        } else if (attachmentCount && folderCount) {
            return attachmentCount + folderCount;
        } else if (attachmentCount && fileCount) {
            return attachmentCount + fileCount;
        } else {
            return folderCount || fileCount || attachmentCount || '--';
        }
    }
    const attachmentCount = getAttachmentCount(item);

    return (
        <>
            <TableRow
                hover
                tabIndex={-1}
                style={{ cursor: "pointer" }}
                className={
                    item.remainingValues_D < 0 || item.remainingValues_D == null
                        ? "red-row"
                        : "none"
                }
            >
                <TableCell>
                    { !permissionCheckFnforCamo({ primaryKey: "ad_status", keyIndex: "U"}) || window?.location?.search.includes('archived-assets')  ? null : (
                        <Tooltip title="Edit">
                            <IconButton
                                className="edit-icon"
                                onClick={(e) => {
                                    handleEditRow(e);
                                }}
                            >
                                <CreateOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {!permissionCheckFnforCamo({ primaryKey: "ad_status", keyIndex: "D"}) ||  window?.location?.search.includes('archived-assets') ? null :
                        assetsDetail.sort((a, b) => b - a).filter(item => item)[0]?.id === item?.id ?
                            (
                                <Tooltip title="Delete" arrow>
                                    <IconButton className="delete-icon" onClick={handleClick}>
                                        <DeleteOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                            ) : null}
                    <RevisionHistory buttonType={true} url={utilurl} />
                </TableCell>
                <TableCell> {item.utilization_period ? item.utilization_period?.data : "--"} </TableCell>
                <TableCell>{item?.tsn ? Comma_format(item?.tsn) : '--'}</TableCell>
                <TableCell> {item.csn ? Comma_format(item?.csn) : '--'} </TableCell>
                <TableCell> {item?.monthly_FH}</TableCell>
                <TableCell> {item?.monthly_FC}</TableCell>
                <TableCell> {item.current_operating_thrust ? item.current_operating_thrust?.name : "--"} </TableCell>
                <TableCell> {item.location_reg_aircraft ? item.location_reg_aircraft : "--"}</TableCell>
                <TableCell> {item.location_position?.label ? item.location_position?.label : "--"} </TableCell>
                <TableCell> {item?.engine_stand_location ? item?.engine_stand_location : "--"} </TableCell>
                <TableCell> {item.location_country ? item.location_country : "--"} </TableCell>
                <TableCell> {item.date_of_installation ? formatDate(item.date_of_installation) : "--"} </TableCell>
                <TableCell> {item.date_of_removal ? formatDate(item.date_of_removal): "--"} </TableCell>
                <TableCell> {item.reason_of_removal ? item.reason_of_removal : "--"} </TableCell>
                <TableCell>
                    {attachmentCount ? (
                        <>
                            <Tooltip title="View Attachments">
                                <span className="center" onClick={() => downAllAttach()} >
                                    <AttachFileIcon className="attach-icon" />
                                    <a href="#" style={{ color: "#0e7fe1" }}>{attachmentCount}</a>
                                </span>
                            </Tooltip>
                        </>
                    )
                        : (
                            "--"
                        )}
                </TableCell>
            </TableRow>
            <Dialog open={open} onClose={handleClose} id="htDialog">
                <ConfirmDialog
                    handleClose={() => handleClose()}
                    handleDeleteRow={handleDeleteRow}
                />
            </Dialog>
            <Dialog
                position="relative"
                maxWidth="lg"
                open={openEditForm}
            >
                <UtilizationFormData
                    actionType={actionType}
                    formTitle={formTitle}
                    closeAddForm={() => closeAddForm()}
                    props={props}
                    editFormId={editFormId}
                    editFormData={editFormData}
                    attachments={attachments}
                    item={item}
                    updateFormData={updateFormData}
                    fileUploadData={fileUploadData}
                    currentRecordPerPage={currentRecordPerPage}
                    uuid={uuid}
                    last_used_folder_uuid={last_used_folder_uuid}
                    last_used_folder_uuid_link_document={last_used_folder_uuid_link_document}
                    checkbox_last_used_folder_uuid={checkbox_last_used_folder_uuid}
                    checkbox_last_used_folder_name={checkbox_last_used_folder_name}
                    getResponseBack={() => getResponseBack()}
                    engineType={engineType}
                    assetsDetail={assetsDetail}
                    basicDetails={basicDetails}
                />
            </Dialog>
            <Dialog
                open={openGetAttachmentsDialogue}
                onClose={handleCloseAttachmentDialogue}
                id="htDialog"
            >
                <ShowAttachments
                    handleClose={() => handleCloseAttachmentDialogue()}
                    handleShowAttachments={AllAttachmentsforDialogue}
                    openAttachment={openAttachment}
                    files={{ title: "Utilization", extension: "zip", key: "" }}
                    downloadAllApi={downloadAllApi}
                    moduleId={moduleId}
                    item={item}
                    basicDetails={basicDetails}
                    removeAttachment={removeAttachment}
                />
            </Dialog>
        </>
    );
};
export default withRouter(UtilizationList);