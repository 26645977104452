import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel,Tooltip, Checkbox, TablePagination,Dialog,IconButton } from "@material-ui/core"
import React, { useState, Fragment } from "react";
import ConfirmDialog from "./ConfirmDialog";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { permissionCheckFnforCamo } from "../../../../utils";

const InventoryTable = ({ bulkOperation ,bulkFlag, heads, noRecord, data, sort = "", sort_by = "", pagination = {}, onChangePage, onChangeRowsPerPage, createSortHandler, classNames, toggleBulkOps, currentRecordPerPage,bulkDeletefn,basicDetails}) => {
	const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
	return (
		<Fragment>
			<div id="AmpTable">
				<Table className="table-wrapper" id="inventory">
					<TableHead>
						<TableRow>
						{ !permissionCheckFnforCamo({ primaryKey: "ad_status", keyIndex: "D"}) || window?.location?.search.includes('archived-assets')  ? null : bulkOperation ? (
                                <TableCell>
                                    <Checkbox
                                        size="small"
                                        checked={bulkFlag}
                                        onChange={(e) => toggleBulkOps(e.target.checked)}
                                        name="bulk_operation"
                                        color="primary"
                                    />
                                    {bulkOperation?.ids?.length > 0 && <Tooltip title="Delete" arrow>
                                        <IconButton className="delete-icon">
                                            <DeleteOutlinedIcon onClick={handleClick} />({bulkOperation?.ids?.length})
                                        </IconButton>
                                    </Tooltip>}
                                </TableCell>
                            ) : null}
							{heads.map((row, index) => (
								<TableCell
									key={index}
									align={row.actionCell ? "right" : "left"}
									padding={"normal"}
									sortDirection={
										sort === row.id ? (sort_by ? sort_by : "asc") : false
									}
								>
									{row.sortOption && noRecord === null ? (
										<TableSortLabel
											active={sort === row.id}
											direction={sort_by && sort === row.id ? sort_by : "asc"}
											onClick={() => createSortHandler(row.id)}
										>
											{row.label}
										</TableSortLabel>
									) : (
										<span>{row.label}</span>
									)}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{noRecord ? (
							<TableRow id="table-no-records">
								<TableCell
									colSpan={bulkOperation ? heads.length + 1 : heads.length}
									align="center"
								>
									{noRecord}
								</TableCell>
							</TableRow>
						) : (
							data
						)}
					</TableBody>
				</Table>
			</div>
			{Object.keys(pagination).length && pagination.total > 0 ? (
				<TablePagination
					rowsPerPageOptions={[10, 20, 50, 100]}
					component="div"
					count={pagination.total}
					rowsPerPage={currentRecordPerPage}
					page={pagination.page - 1}
					backIconButtonProps={{ "aria-label": "previous page" }}
					nextIconButtonProps={{ "aria-label": "next page" }}
					onChangePage={onChangePage}
					onChangeRowsPerPage={onChangeRowsPerPage}
				/>
			) : null}
			   <Dialog open={open} onClose={handleClose} id="htDialog">
                <ConfirmDialog
                    handleClose={() => handleClose()}
                    handleDeleteRow={bulkDeletefn}
                    title={bulkOperation?.type === "bulk" ? "Do you really want to delete all the records?" :"Do you really want to delete selected records?"}
                />
            </Dialog>
		</Fragment>
	)
}

export default InventoryTable