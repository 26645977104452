import { regexConstants } from "../../constants/regEx"
import { getLocalStorageInfo } from "../../utils"
export const assetListHd = [
  { id: "asset_name", label: "Type and Model", sortOption: true },
  { id: "asset_name", label: "Serial Number", sortOption: true },
  { id: "current_registration_number", label: "Registration Number", sortOption: true, },
  { id: "lessor", label: "Lessor", sortOption: true },
  { id: "owner", label: "Owner", sortOption: true },
  { id: "location", label: "Location", sortOption: true },
  { id: "status", label: "Lease Status", sortOption: true },
  { id: "action", label: "Action", sortOption: false },
]
export const smbcHeader = [
  { id: "engine_type", label: "Type and Model", sortOption: true },
  { id: "asset_name", label: "ESN", sortOption: true },
  { id: "lessee", label: "Lessee", sortOption: true, },
  { id: "operator", label: "Operator", sortOption: true },
  { id: "status", label: "Lease Status", sortOption: true },
  { id: "on_wing_msn", label: "Current Status", sortOption: true },
  { id: "action", label: "Action", sortOption: false },
]
export const notificationFilter={
  'status':{
      'inputType': 'dropdown',
      'title': 'Status',
      'options':[{label:'Read', value:1}, {label:'UnRead', value:2}],
      'labelKey':'label',
      'valueKey':'value',
      'multiple':false
  },
  'start_date': {
      'inputType': 'date',
      'keyParam': 'start_date',
      'title':'Start Date'
  },
  'end_date': {
      'inputType': 'date',
      'keyParam': 'end_date',
      'title':'End Date'
    },
}
export const aircraftCrud = {
  aircraft_type: null,
  msn: "",
  date_of_manufacture: null,
  credit_classification: null,
  maintenance_event_program_group: null,
  latitude: "",
  longitude: "",
  country: "",
  status: null,
  same_operator_lessee: false,
  ownership_type: 1,
  current_registration_number: "",
  country_code: "IN",
  sub_status: null,
  off_lease_status: null,
  lessee: null,
  operator: null,
}
export const engineCrud = {
  esn: "",
  tsn: "",
  csn: "",
  operating_thrust: "",
  purchase_thrust: "",
  operator_id: null,
  average_monthly_hours: "",
  average_monthly_cycles: "",
  location: "",
  engine_type_id: null,
  manufacturing_date: null,
  position: null,
  portfolio: null,
  credit_classification: null,
  status: null,
  ownership_type: 1,
  same_operator_lessee: false,
  date_of_installation: null,
  region: null,
  match_csn_to_llp: false,
  engine_module_type_id: null, // Applicable for Engine Module
  constructed_model: "", // Need to check this fields why we are using
  owner_id: 13, // Need to check this fields why we are using
  owner_level: 1, // Need to check this fields why we are using
  lessor_name_id: 13, // Need to check this fields why we are using
  lessor_level: 1, // Need to check this fields why we are using
  cslv:null,
  tslv:null,
  operating_thrust_current:null,
  llp_limiter:0

}
export const aircraftErrorCode = {
  msn: {
    0: "",
    1: "Please enter MSN",
  },
  msnObj: {
    required: true,
  },
  aircraft_type: {
    0: "",
    1: "Please select Aircraft Type",
  },
  aircraft_typeObj: {
    required: true,
  },

  aircraft_type_name: {
    0: "",
    1: "Please select Aircraft Type",
  },
  aircraft_type_nameObj: {
    required: true,
  },

  date_of_manufacture: {
    0: "",
    1: "Please enter Date of Manufacture",
    5: "Please enter a valid date",
  },
  date_of_manufactureObj: {
    required: true,
    datePattern: true,
  },
  status: {
    0: "",
    1: "Please select Lease Status",
  },
  statusObj: {
    required: true,
  },
  operator: {
    0: "",
    1: "Please select Operator",
  },
  operator_obj: {
    required: true,
  },
  lessee_id: {
    0: "",
    1: "Please select Lessee",
  },
  lessee_idObj: {
    required: true,
  },
  sub_status: {
    0: "",
    1: "Please select Sub Status",
  },
  sub_statusObj: {
    required: true,
  },
  tsn: {
    0: "",
    1: "Please enter TSN",
  },
  tsnObj: {
    required: true,
  },
  csn: {
    0: "",
    1: "Please enter CSN",
  },
  csnObj: {
    required: true,
  },
}
export const engineErrorCode = {
  esn: {
    0: "",
    1: "Please enter ESN",
  },
  esnObj: {
    required: true,
  },
  engine_type_id: {
    0: "",
    1: "Please select Engine Type",
  },
  engine_type_idObj: {
    required: true,
  },
  manufacturing_date: {
    0: "",
    1: "Please enter Date of Manufacture",
    5: "Please enter a valid date",
  },
  manufacturing_dateObj: {
    required: true,
    datePattern: true,
  },
  status: {
    0: "",
    1: "Please select Lease Status",
  },
  statusObj: {
    required: true,
  },
  region_of_operation: {
    0: "",
    1: "Please select Region of Operation",
  },
  region_of_operationObj: {
    required: true,
  },
  operator_id: {
    required: true,
    0: "",
    1: "Please select Operator",
  },
  operator_idObj: {
    required: true,
  },
  lessee_id: {
    0: "",
    1: "Please select Lessee",
  },
  lessee_idObj: {
    required: true,
  },
  position: {
    0: "",
    1: "Please select Position",
  },
  positionObj: {
    required: true,
  },
  sub_status: {
    0: "",
    1: "Please select Sub Status",
  },
  sub_statusObj: {
    required: true,
  },
}

export const maintenanceFilterOps = {
  aircraft_type: {
    inputType: "dropdown",
    placeholder: "Select Aircraft Type",
    title: "Aircraft Type",
    options: [],
    labelKey: "name",
    valueKey: "id",
    multiple: true,
  },
  engine_type: {
    inputType: "dropdown",
    placeholder: "Select Engine Type",
    title: "Engine Type",
    options: [],
    labelKey: "name",
    valueKey: "id",
    multiple: true,
  },
  lessee: {
    inputType: "dropdown",
    placeholder: "Select Lessee",
    title: "Lessee",
    options: [],
    labelKey: "name",
    valueKey: "slug",
    multiple: true,
  },
  lessor_name: {
    inputType: "text",
    placeholder: "Enter Lessor",
    title: "Lessor",
  },
  owner: {
    inputType: "text",
    placeholder: "Enter Owner ",
    title: "Owner",
  },
  ownership_type: {
    inputType: "dropdown",
    placeholder: "Select Ownership Type",
    title: "Ownership",
    options: [
      { label: "Owned", value: 1 },
      { label: "Managed", value: 2 },
    ],
    labelKey: "label",
    valueKey: "value",
    multiple: true,
  },
  registration: {
    inputType: "text",
    placeholder: "Enter Registration Number",
    title: "Registration Number",
  },
  portfolio: {
    inputType: "text",
    placeholder: "Enter Portfolio",
    title: "Portfolio",
  },
  serial_number: {
    inputType: "text",
    placeholder: "Enter Serial Number",
    title: "Serial Number",
  },
  status: {
    inputType: "dropdown",
    placeholder: "Select Status",
    title: "Status",
    options:
      getLocalStorageInfo() &&
      getLocalStorageInfo().defaultLessor &&
      getLocalStorageInfo().defaultLessor.id === 442
        ? [
            { label: "Prospect", value: "11" },
            { label: "In Storage", value: "10" },
            { label: "Lease Return", value: "9" },
            { label: "MOU Signed", value: "8" },
            { label: "MOU Issued", value: "7" },
            { label: "Lease Issued", value: "6" },
            { label: "Archive", value: "5" },
            { label: "Written Off/ Sold / Part out", value: "4" },
            { label: "OnGround", value: "3" },
            { label: "Off Lease", value: "2" },
            { label: "On Lease", value: "1" },
          ]
        : [
            { label: "Archive", value: "5" },
            { label: "On Lease", value: "1" },
            { label: "Off Lease", value: "2" },
            { label: "On Ground", value: "3" },
            { label: "Written Off/ Sold / Part out", value: "4" },
          ],
    labelKey: "label",
    valueKey: "value",
    multiple: true,
  },
}

export const smbcFilters = {
  engine_type: {
    inputType: "dropdown",
    placeholder: "Select Engine Type",
    title: "Engine Type",
    options: [],
    labelKey: "name",
    valueKey: "id",
    multiple: true,
  },
  serial_number: {
    inputType: "text",
    placeholder: "Enter Serial Number",
    title: "Serial Number",
  },
  lessee: {
    inputType: "dropdown",
    placeholder: "Select Lessee",
    title: " Lessee",
    options: [],
    labelKey: "name",
    valueKey: "slug",
    multiple: true,
  },
  operator: {
    inputType: "dropdown",
    placeholder: "Select operator",
    title: "Operator",
    options: [],
    labelKey: "name",
    valueKey: "slug",
    multiple: true,
  },

  on_wing_msn: {
    inputType: "text",
    placeholder: "Select current Status",
    title: "Current Status",
  },

  status: {
    inputType: "dropdown",
    placeholder: "Select Status",
    title: "Status",
    options:
      getLocalStorageInfo() &&
      getLocalStorageInfo().defaultLessor &&
      getLocalStorageInfo().defaultLessor.id === 442
        ? [
            { label: "Prospect", value: "11" },
            { label: "In Storage", value: "10" },
            { label: "Lease Return", value: "9" },
            { label: "MOU Signed", value: "8" },
            { label: "MOU Issued", value: "7" },
            { label: "Lease Issued", value: "6" },
            { label: "Archive", value: "5" },
            { label: "Written Off/ Sold / Part out", value: "4" },
            { label: "OnGround", value: "3" },
            { label: "Off Lease", value: "2" },
            { label: "On Lease", value: "1" },
          ]
        : [
            { label: "Archive", value: "5" },
            { label: "On Lease", value: "1" },
            { label: "Off Lease", value: "2" },
            { label: "On Ground", value: "3" },
            { label: "Written Off/ Sold / Part out", value: "4" },
          ],
    labelKey: "label",
    valueKey: "value",
    multiple: true,
  },
}
